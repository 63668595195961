<template>
  <div class="page-wrap text-robot">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">文字客服机器人</div>
          <div class="desc">基于NLP自然语言理解技术实现多轮对话能力，打造AI客服机器人+智能知识库为核心的智能客服体系。实现全天候、多渠道业务服务数据查询、服务办理等用户需求。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
        <div class="right">
          <div class="bg-right"></div>
        </div>
      </div>
    </div>

    <!-- 产品功能 -->
    <div class="section bg-white func">
      <div class="title">产品功能</div>
      <div class="content">
        <div class="list">
          <div class="item">
            <div class="text-info" style="width: 466px;">
              <div class="title">知识问答与知识管理</div>
              <div class="desc">通过机器阅读技术，自动生成智能问答对与结构化知识，机器人可直接基于阅读理解内容进行智能问答，同时知识库内置多种配置功能，帮助企业完成知识从构建到失效全生命周期管理。</div>
            </div>
            <img src="@/assets/text-robot/fun-1.png" style="width: 638px;height: 427px" />
          </div>
          <div class="item">
            <img src="@/assets/text-robot/fun-2.png" style="width: 763px;height: 550px" />
            <div class="text-info" style="width: 485px;">
              <div class="title">全渠道接入</div>
              <div class="desc">支持电话、微信、WEB端、APP等多渠道接入，统一平台管理，同时 支持文字、语音、图片、链接、富文本等格式信息交互，7*24小时全天候无休服务，保障客户随时随地的咨询与服务体验</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info" style="width: 466px;">
              <div class="title">在线会话智能辅助</div>
              <div class="desc">当访客由人工接入后，文字客服机器人可为企业人工坐席提供实时会话工作台、知识检索、快捷回复、会话小结、知识推荐等智能辅助，提高工作效率。</div>
            </div>
            <img src="@/assets/text-robot/fun-3.png" style="width: 638px;height: 426px" />
          </div>
          <div class="item">
            <img src="@/assets/text-robot/fun-4.png" style="width: 690px;height: 468px" />
            <div class="text-info" style="width: 468px;">
              <div class="title">多维统计与实时监控</div>
              <div class="desc">系统支持坐席、坐席组等服务过程的全方位监控及数据报表查看，确保服务流程的每个环节都在监控之中；同时通过多维度数据报表，实时查看在线服务状态，为企业运营决策提供支持</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="section advantage">
      <div class="title">产品优势</div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item, index) in advantageList" :key="index">
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用场景 -->
    <div class="section bg-white scene">
      <div class="title">应用场景</div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item, index) in sceneList" :key="index">
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="section case">
      <div class="title">客户案例</div>
      <div class="content">
        <div class="detail-panel">
          <div class="left"></div>
          <div class="right">
            <div class="top-info">
              <div class="title">金融案例：某银行智能文本客服项目</div>
              <el-timeline>
                <el-timeline-item
                    v-for="(item, index) in timeList"
                    :key="index"
                >
                  <span class="name">{{ item.name }}</span>
                  <span class="content">{{ item.content }}</span>
                </el-timeline-item>
              </el-timeline>
            </div>
            <div class="info-panel">
              <div class="item">
                <div class="title">知识库建设</div>
                <div class="desc">梳理1700个全量知识点，其中重点优化472个高频知识点</div>
              </div>
              <div class="item">
                <div class="title">业务完成率</div>
                <div class="desc">每日接受客户服务1000+人次，在线客服直接处理完成率超过70%</div>
              </div>
            </div>
            <div class="info-panel" style="margin-top: 20px">
              <div class="item">
                <div class="title">前端及接口建设</div>
                <div class="desc">上线5个渠道：官网、手机APP、网上银行、微信，深蓝知识库。同时积累了对接经验，可以复用到之后更多文字客服项目</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />
    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";

export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      advantageList: [
        {
          name: '多轮对话交互',
          content: '支持完成复杂的任务，如订单查询、预约服务、投诉处理等，通过一系列对话步骤实现用户需求的满足。',
          icon: 'icon-adv-1.png'
        },
        {
          name: '智能知识库',
          content: '基于企业提供的FAQ、产品说明、服务政策等文档，构建丰富的知识库，机器人可以从中检索并生成合适的回复。',
          icon: 'icon-adv-2.png'
        },
        {
          name: '智能知识推荐',
          content: '可根据访客咨询的内容，结合智能知识库自动推荐最匹配的答案，基于用户对话内容自动学习并优化智能知识库，不断提升机器人能力',
          icon: 'icon-adv-3.png'
        },
        {
          name: '行业语料训练',
          content: '海量行业典型应用知识内容推荐，有效协助客户完成信息查询、业务办理等复杂任务',
          icon: 'icon-adv-4.png'
        }
      ],
      sceneList: [
        {
          name: '售前产品咨询',
          content: '客户可以通过文字客服机器人了解产品的详细信息，如功能、规格、价格等。机器人可以快速响应，提供准确的信息，帮助客户做出购买决策。',
          icon: 'icon-scene-1.png'
        },
        {
          name: '售中订单咨询',
          content: '客户可以查询订单状态、修改订单信息或取消订单，机器人能够实时处理这些请求，提高服务效率。',
          icon: 'icon-scene-2.png'
        },
        {
          name: '售后服务咨询',
          content: '客户可以通过机器人提交投诉，机器人会记录并转交给相关部门处理，同时提供投诉进度查询功能。',
          icon: 'icon-scene-3.png'
        },
        {
          name: '企业内部咨询',
          content: '企业可以将常见问题和解答整理成知识库，供机器人调用，实现知识的共享和传承。',
          icon: 'icon-scene-4.png'
        }
      ],
      timeList: [
        {
          name: '开始时间',
          content: `2020年7月项目启动`,
        },
        {
          name: '完成动作',
          content: `需求调研、需求跟踪、原型设计需求沟通`,
        },
        {
          name: '结束时间',
          content: `2020年12月中`,
        },
        {
          name: '完成动作',
          content: `开发测试、系统交付、运营培训`,
        }
      ]
    }
  },
  methods: {
    getIcon(item) {
      return require(`@/assets/text-robot/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
